








































































































































































































import Vue from 'vue';
import { ApiResource } from '@/types';

export default Vue.extend({
  props: {
    account: {
      type: Object as () => ApiResource.Business.Account,
      required: true,
    },
    business: {
      type: Object as () => ApiResource.Business,
      required: true,
    },
  },
  computed: {
    basic(): boolean {
      return this.basicVerifiedEmail
          && this.basicIdentityVerification
          && this.basicProfileAge;
    },
    basicVerifiedEmail(): boolean {
      return true; // !!this.business.email;
    },
    basicIdentityVerification(): boolean {
      return this.account.requirements.eventually_due.length === 0
          && this.account.requirements.currently_due.length === 0
          && this.account.requirements.past_due.length === 0;
    },
    basicProfileAge(): boolean {
      // Profile is older than 10 million seconds (4 months ≈ 10.5M seconds)
      return (Date.now() - (this.business.created_at * 1000)) > (10000000 * 1000);
    },
  },
});
