



























































































































































import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import AccountStatus from '@/components/dashboard/AccountStatus.vue';
import VerifiedBadge from '@/components/dashboard/VerifiedBadge.vue';

interface VuexBindings {
  detect: (idOrVanity: string) => ApiResource.Business,
  findAccount: (id: string) => ApiResource.Business.Account | undefined,
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    AccountStatus,
    VerifiedBadge,
  },
  data() {
    return {
      error: null as null|Error,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
      findAccount: 'business/FindAccount',
    }),
    VANITY(): string {
      return this.$route.params.VANITY;
    },
    account(): ApiResource.Business.Account | undefined {
      return this.findAccount(this.business.id);
    },
    business(): ApiResource.Business {
      // Fallback to finding by ID automatically
      return this.detect(this.VANITY);
    },
  },
  mounted() {
    this.fetchVerificationDetails();

    if (this.$route.query.onboarding_result === 'failure') {
      this.redirectToHostedOnboarding(
        (this.$route.query.onboarding_type as string|undefined),
        (this.$route.query.onboarding_collect as string|undefined),
      );
    }
  },
  methods: {
    async fetchVerificationDetails() {
      await this.$store.dispatch('business/GetAccount', {
        BUSINESS_ID: this.business.id,
      });
    },
    async redirectToHostedOnboarding(type = 'update', collect: string|null = null) {
      this.loading = true;

      const data = await this.$store.dispatch('business/GetAccountLink', {
        BUSINESS_ID: this.business.id,
        form: {
          type, collect,
        },
      }).catch((e) => {
        this.error = e;
        this.loading = false;
      });

      if (!data) return;

      window.location.href = data.url;
    },
  },
});
