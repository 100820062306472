var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.account)?_c('main',[_c('h2',{staticClass:"text-2xl font-semibold mb-3",domProps:{"textContent":_vm._s(_vm.$t('status.title'))}}),_c('AccountStatus',{attrs:{"account":_vm.account}}),_c('h3',{staticClass:"text-xl font-medium mb-3",domProps:{"textContent":_vm._s(_vm.$t('verification.title'))}}),_c('ErrorMessage',{attrs:{"error":_vm.error}}),(_vm.account.requirements.past_due.length > 0
      && !(_vm.account.requirements.past_due.length === 1
        && _vm.account.requirements.past_due.includes('external_account')))?_c('BaseMessage',{staticClass:"is-danger"},[_c('p',{staticClass:"mb-3",domProps:{"textContent":_vm._s(_vm.$t('past_due.message'))}}),_c('BaseLevel',{scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('div',{staticClass:"level-item"},[_c('BaseButton',{staticClass:"is-medium is-needy is-danger",attrs:{"type":"button","is-loading":_vm.loading},domProps:{"textContent":_vm._s(_vm.$t('past_due.redirect'))},on:{"click":function($event){return _vm.redirectToHostedOnboarding('verify', 'current')}}})],1)]},proxy:true}],null,false,58981850)})],1):(_vm.account.requirements.currently_due.length > 0
      && !(_vm.account.requirements.currently_due.length === 1
        && _vm.account.requirements.currently_due.includes('external_account')))?_c('BaseMessage',{staticClass:"is-dark"},[_c('p',{staticClass:"mb-3",domProps:{"textContent":_vm._s(_vm.$t('currently_due.message'))}}),_c('BaseLevel',{scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('div',{staticClass:"level-item"},[_c('BaseButton',{staticClass:"is-medium is-needy is-link",attrs:{"is-loading":_vm.loading},domProps:{"textContent":_vm._s(_vm.$t('currently_due.redirect'))},on:{"click":function($event){return _vm.redirectToHostedOnboarding('verify', 'current')}}})],1)]},proxy:true}],null,false,3325703699)})],1):(_vm.account.requirements.eventually_due.length > 0
      && !(_vm.account.requirements.eventually_due.length === 1
        && _vm.account.requirements.eventually_due.includes('external_account')))?_c('BaseMessage',{staticClass:"is-link"},[_c('p',{staticClass:"mb-3",domProps:{"textContent":_vm._s(_vm.$t('eventually_due.message'))}}),_c('BaseLevel',{scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('div',{staticClass:"level-item"},[_c('BaseButton',{staticClass:"is-medium is-needy has-text-link",attrs:{"is-loading":_vm.loading},domProps:{"textContent":_vm._s(_vm.$t('eventually_due.redirect'))},on:{"click":function($event){return _vm.redirectToHostedOnboarding('verify', 'eventual')}}})],1)]},proxy:true}],null,false,1511651395)})],1):_c('BaseMessage',{staticClass:"is-link"},[_c('p',{staticClass:"mb-3",domProps:{"textContent":_vm._s(_vm.$t('all_done.message'))}}),_c('BaseLevel',{scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('div',{staticClass:"level-item"},[_c('BaseButton',{staticClass:"is-medium is-needy is-link",attrs:{"is-loading":_vm.loading},domProps:{"textContent":_vm._s(_vm.$t('all_done.redirect'))},on:{"click":function($event){return _vm.redirectToHostedOnboarding('update', null)}}})],1)]},proxy:true}],null,false,2079726702)})],1),(_vm.account.requirements.eventually_due.includes('external_account')
      || _vm.account.requirements.currently_due.includes('external_account')
      || _vm.account.requirements.past_due.includes('external_account'))?_c('BaseMessage',{staticClass:"is-danger"},[_c('p',{staticClass:"mb-3",domProps:{"textContent":_vm._s(_vm.$t('bank_account.message'))}}),_c('BaseLevel',{scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('div',{staticClass:"level-item"},[_c('RouterLink',{staticClass:"button is-medium has-text-danger",attrs:{"is-loading":_vm.loading,"to":{ name: 'dashboard.external-accounts' }},domProps:{"textContent":_vm._s(_vm.$t('bank_account.redirect'))}})],1)]},proxy:true}],null,false,519555001)})],1):_vm._e(),_c('BaseModal',{attrs:{"is-active":_vm.loading,"has-card-head":""}},[_c('BaseMessage',{staticClass:"is-medium is-link"},[_c('p',{domProps:{"textContent":_vm._s(_vm.$t('redirecting'))}})])],1),(_vm.$ff.VERIFIED_BADGE)?_c('VerifiedBadge',{attrs:{"business":_vm.business,"account":_vm.account}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }