

















































import Vue from 'vue';
import { ApiResource } from '@/types';

export default Vue.extend({
  props: {
    account: {
      type: Object as () => ApiResource.Business.Account,
      required: true,
    },
  },
});
